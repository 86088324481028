import clsx from 'clsx';
import { Link } from 'gatsby';
import logo from 'images/logo.png';
import React, { ReactElement, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Nav from './nav';
import { ColoredHeader, HeaderSearch, Logo, TogglerButton, WhatsappLink } from './styled';

const Header = (): ReactElement => {
    const { t } = useTranslation('layout');
    const [isOpen, setIsOpen] = useState(false);

    return (
        <ColoredHeader>
            <Container className="px-0">
                <nav className="navbar navbar-expand-xl navbar-dark px-0">
                    <div className="flex-wrap d-flex align-items-end flex-grow-1 row gx-4 mx-0">
                        <Col xs={6} xl={2}>
                            <Link to="/" className="navbar-brand d-block mx-3">
                                <Logo src={logo} alt="Ideal villa rental" width={148} height={49} />
                            </Link>
                            <HeaderSearch className="text-white px-4">{t('layout:header.search')}</HeaderSearch>
                        </Col>
                        <Col xs={{ span: 2, offset: 1 }} className="align-self-center d-block d-xl-none">
                            <WhatsappLink
                                href="https://wa.me/590690470252/?text=Hello%20IDEAL%20Villa%20Rentals"
                                target="_blank"
                                rel="noreferrer"
                            />
                        </Col>
                        <Col xs={3} className="text-end align-self-center d-block d-xl-none">
                            <TogglerButton
                                className="navbar-toggler me-1"
                                type="button"
                                data-toggle="collapse"
                                date-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded={isOpen}
                                aria-label="Toggle"
                                onClick={(): void => setIsOpen(!isOpen)}
                            >
                                <span className="navbar-toggler-icon" />
                            </TogglerButton>
                        </Col>
                        <Col xs={12} xl={10} className="d-inline-block">
                            <div className="flex-grow-1 pl-0 pl-xl-5 h-100">
                                <div
                                    className={clsx({ 'navbar-collapse': true, collapse: !isOpen, 'pt-3': true })}
                                    id="navbarSupportedContent"
                                >
                                    <Nav onClickLink={(): void => setIsOpen(false)} />
                                </div>
                            </div>
                        </Col>
                    </div>
                </nav>
            </Container>
        </ColoredHeader>
    );
};

export default Header;
