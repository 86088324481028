import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { StyledNavbar } from 'components/Layout/Header/styled';
import { Link } from 'gatsby';
import React, { ReactElement } from 'react';
import { Nav as BsNav, NavItem, NavDropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import LanguageSwitcher from './languageSwitcher';

const navItems = [
    {
        translationKey: 'header.villa',
        routes: {
            fr: '/locations',
            en: '/rentals',
        },
    },
    {
        translationKey: 'header.services',
        routes: {
            fr: '/services',
            en: '/services',
        },
    },
    {
        translationKey: 'header.st_barth.title',
        sub: [
            {
                translationKey: 'header.st_barth.trip',
                routes: {
                    fr: '/saint-barth/voyager',
                    en: '/saint-barth/trip',
                },
            },
            {
                translationKey: 'header.st_barth.discover',
                routes: {
                    fr: '/saint-barth/decouvrir',
                    en: '/saint-barth/discover',
                },
            },
        ],
    },
    {
        translationKey: 'header.special_offers',
        extraClasses: ['special-offer'],
        routes: {
            fr: '/offres-speciales',
            en: '/special-offers',
        },
    },
    {
        translationKey: 'header.contact',
        routes: {
            fr: '/contact',
            en: '/contact-us',
        },
    },
];

type Props = {
    onClickLink: () => any;
};

const Nav = ({ onClickLink }: Props): ReactElement => {
    const location = useLocation();
    const { t, i18n } = useTranslation('layout');
    const locale = (i18n.language || 'fr').substr(0, 2) as 'fr' | 'en';

    return (
        <StyledNavbar className="navbar-nav ms-auto text-uppercase d-flex flex-grow-1 justify-content-between" as="ul">
            {navItems.map((navItem) => {
                if (navItem.routes) {
                    const extraClasses = (navItem.extraClasses || []).reduce((classes, className) => {
                        classes[className] = true;
                        return classes;
                    }, {} as Record<string, boolean>);
                    const route = navItem.routes[locale];
                    const classNames = clsx({
                        'py-2': true,
                        'py-xl-0': true,
                        active: location.pathname === route,
                        ...extraClasses,
                    });

                    return (
                        <NavItem as="li" className={classNames} key={navItem.translationKey}>
                            <Link to={route} className="nav-link py-1 px-2 text-white" onClick={onClickLink}>
                                <Trans i18nKey={navItem.translationKey} t={t} />
                            </Link>
                        </NavItem>
                    );
                } else if (navItem.sub) {
                    const extraClasses = (navItem.extraClasses || []).reduce((classes, className) => {
                        classes[className] = true;
                        return classes;
                    }, {} as Record<string, boolean>);
                    const classes = {
                        'py-2': true,
                        'py-xl-0': true,
                        'text-white': true,
                        active: navItem.sub.find((subItem) => subItem.routes[locale] === location.pathname),
                        ...extraClasses,
                    };
                    return (
                        <React.Fragment key={navItem.translationKey}>
                            <NavDropdown
                                title={<Trans i18nKey={navItem.translationKey} t={t} />}
                                as="li"
                                className={clsx({ ...classes, 'd-none': true, 'd-xl-inline': true })}
                            >
                                {navItem.sub.map((subItem) => (
                                    <NavDropdown.Item
                                        href={subItem.routes[locale]}
                                        key={subItem.translationKey}
                                        className="text-white"
                                    >
                                        <Trans i18nKey={subItem.translationKey} t={t} />
                                    </NavDropdown.Item>
                                ))}
                            </NavDropdown>
                            <NavItem as="li" className={clsx({ ...classes, 'd-inline': true, 'd-xl-none': true })}>
                                <BsNav.Link className="py-0 px-2 text-white d-inline-block">
                                    <Trans i18nKey={navItem.translationKey} t={t} />
                                </BsNav.Link>
                                <br />
                                {navItem.sub.map((subItem) => (
                                    <Link
                                        to={subItem.routes[locale]}
                                        onClick={onClickLink}
                                        className="nav-link py-0 px-2 text-white d-inline-block ms-2 sub-element"
                                        key={subItem.translationKey}
                                    >
                                        <Trans i18nKey={subItem.translationKey} t={t} />
                                    </Link>
                                ))}
                            </NavItem>
                        </React.Fragment>
                    );
                }
            })}
            <LanguageSwitcher />
        </StyledNavbar>
    );
};

export default Nav;
