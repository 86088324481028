const sizes: DeviceSizes = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
};

const cssBreakpoint: CSSBreakpoint = (size) => `(min-width: ${sizes[size]}px)`;

export { cssBreakpoint, sizes };
