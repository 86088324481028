import facebook from 'images/facebook.png';
import instagram from 'images/instagram.png';
import logo from 'images/logo.png';
import React, { ReactElement } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Follow, FooterLink, Spacer, StyledFooter } from './styled';

const Footer = (): ReactElement => {
    const { t, i18n } = useTranslation('layout');
    return (
        <StyledFooter>
            <Container className="px-0 pt-4 pt-xl-5 g-0">
                <Row className="g-0 align-items-center justify-content-between flex-xl-row d-none d-xl-flex">
                    <Col xs={7}>
                        <ul className="d-flex mb-0 justify-content-around">
                            <li className="col-auto">
                                <FooterLink
                                    to={`/services?_locale=${i18n.language || 'fr'}`}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.butler</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/contact' : '/contact-us'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.contact</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/mentions-legales' : '/legal-notice'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.legal</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/politique-de-confidentialite' : '/privacy-policy'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.privacy</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/conditions-covid-19' : '/covid-19-condition'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.covid</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto">
                                <FooterLink to={'/last-minute'} className="text-white text-uppercase">
                                    <Trans t={t}>layout:footer.links.last_minute</Trans>
                                </FooterLink>
                            </li>
                        </ul>
                    </Col>
                    <Follow xs={3}>
                        <span className="text-white">
                            <Trans t={t}>layout:footer.follow_us</Trans>
                        </span>
                        <a
                            href="https://www.facebook.com/IDEALSaintBarth"
                            className="mx-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={facebook} alt="Facebook" />
                        </a>
                        <a
                            href="https://www.instagram.com/idealstbarth/"
                            className="ml-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={instagram} alt="Facebook" />
                        </a>
                    </Follow>
                    <Col xs={2} className="text-center">
                        <Image src={logo} alt="Ideal St Barth" width="148" height="49" />
                    </Col>
                </Row>
                <Row className="d-xl-none g-0">
                    <Col xs={12} className="text-center px-5">
                        <Image src={logo} alt="Ideal St Barth" width="148" height="49" />
                    </Col>
                    <Col xs={12} className="text-center px-5">
                        <ul className="list-unstyled d-flex row mb-0 justify-content-around">
                            <li className="col-auto text-nowrap my-3">
                                <FooterLink
                                    to={`/services?_locale=${i18n.language || 'fr'}`}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.butler</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-12" />
                            <li className="col-auto text-nowrap my-3">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/contact' : '/contact-us'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.contact</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto text-nowrap my-3">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/mentions-legales' : '/legal-notice'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.legal</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto text-nowrap my-3">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/politique-de-confidentialite' : '/privacy-policy'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.privacy</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto text-nowrap my-3">
                                <FooterLink
                                    to={i18n.language === 'fr' ? '/conditions-covid-19' : '/covid-19-condition'}
                                    className="text-white text-uppercase"
                                >
                                    <Trans t={t}>layout:footer.links.covid</Trans>
                                </FooterLink>
                            </li>
                            <li className="col-auto text-nowrap my-3">
                                <FooterLink to={'/last-minute'} className="text-white text-uppercase">
                                    <Trans t={t}>layout:footer.links.last_minute</Trans>
                                </FooterLink>
                            </li>
                        </ul>
                    </Col>
                    <Spacer />
                    <Follow xs={12} className="mt-3">
                        <span className="text-white">
                            <Trans t={t}>layout:footer.follow_us</Trans>
                        </span>
                        <a
                            href="https://www.facebook.com/IDEALSaintBarth"
                            className="mx-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={facebook} alt="Facebook" />
                        </a>
                        <a
                            href="https://www.instagram.com/idealstbarth/"
                            className="ml-1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={instagram} alt="Facebook" />
                        </a>
                    </Follow>
                </Row>
            </Container>
        </StyledFooter>
    );
};

export default Footer;
