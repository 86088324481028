import { cssBreakpoint } from 'device';
import togglerOpen from 'images/burger-menu.svg';
import togglerClose from 'images/cross-menu.svg';
import whatsapp from 'images/whatsapp.svg';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

const ColoredHeader = styled.header`
    background-color: ${(props): string => props.theme.ivr.colors.deepBlue[75]};
    font-size: 1em;
    font-weight: 300;
    min-height: ${(props): string => props.theme.ivr.header.height};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${(props): string => props.theme.ivr.header.zIndex};
    @media screen and ${cssBreakpoint('xl')} {
        min-height: calc(${(props): string => props.theme.ivr.header.height} + 15px);
        z-index: ${(props): string => (parseInt(props.theme.ivr.header.zIndex, 10) * 2).toString()};
    }
`;

const Logo = styled.img`
    height: auto;
    max-width: 106px;
    @media screen and ${cssBreakpoint('xl')} {
        max-width: unset;
    }
`;

const HeaderSearch = styled.div`
    display: none;
    font-size: 1.2rem;
    line-height: 45px;
`;

const WhatsappLink = styled.a`
    background-image: url('${whatsapp}');
    display: block;
    height: 40px;
    width: 40px;
`;

const TogglerButton = styled.button`
    border: 0 !important;

    .navbar-toggler-icon {
        background-image: url('${togglerOpen}') !important;
    }

    &[aria-expanded='true'] {
        .navbar-toggler-icon {
            background-image: url('${togglerClose}') !important;
        }
    }

    &:focus {
        box-shadow: none !important;
        outline: unset;
    }
`;

const StyledNavbar = styled(Nav)`
    .nav-item {
        position: relative;

        &.special-offer {
            border: 1px solid ${(props): string => props.theme.ivr.colors.golden[100]};

            span {
                color: ${(props): string => props.theme.ivr.colors.golden[100]};
                font-weight: bold;
            }
        }

        &.active,
        &:hover {
            @media screen and ${cssBreakpoint('xl')} {
                &::before {
                    background-color: ${(props): string => props.theme.ivr.colors.golden[100]};
                    content: '';
                    height: 3px;
                    position: absolute;
                    top: -0.8rem;
                    width: 100%;
                }
            }
        }
    }

    .sub-element {
        font-size: 0.9rem;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .dropdown > a {
        color: #fff !important;
        padding: 0.25rem 0.5rem;
    }

    .dropdown-menu {
        background-color: ${(props): string => props.theme.ivr.colors.deepBlue[100]};
        min-width: 1rem;

        .dropdown-item:hover {
            background-color: ${(props): string => props.theme.ivr.colors.deepBlue[100]};
            color: ${(props): string => props.theme.ivr.colors.white[50]} !important;
        }
    }
`;

export { ColoredHeader, HeaderSearch, Logo, StyledNavbar, TogglerButton, WhatsappLink };
