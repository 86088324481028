import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const detectorOptions = {
    order: ['querystring', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    lookupQuerystring: '_locale',
};

// noinspection JSIgnoredPromiseFromCall
i18next
    .use(intervalPlural)
    .use(LanguageDetector)
    .init({
        cleanCode: true,
        nonExplicitSupportedLngs: true,
        fallbackLng: (code) => {
            code = (code || '').substr(0, 2);
            const supported = ['fr', 'en'];
            if (supported.includes(code)) {
                return code;
            }

            return 'fr';
        },
        resources: {
            fr: {
                layout: require('../../locales/fr/layout.json'),
                home: require('../../locales/fr/home.json'),
                form: require('../../locales/fr/form.json'),
                rental: require('../../locales/fr/rental.json'),
                static: require('../../locales/fr/static.json'),
            },
            en: {
                layout: require('../../locales/en/layout.json'),
                home: require('../../locales/en/home.json'),
                form: require('../../locales/en/form.json'),
                rental: require('../../locales/en/rental.json'),
                static: require('../../locales/en/static.json'),
            },
        },
        ns: ['layout', 'home', 'form', 'rental', 'static'],
        defaultNS: 'layout',
        returnObjects: true,
        debug: false,
        interpolation: {
            escapeValue: false,
            format: (value: any, rawFormat?: string, lng?: string): string => {
                if (rawFormat) {
                    const [format, decimals] = rawFormat.split(',').map((v) => v.trim());
                    switch (format) {
                        case 'price':
                            return Intl.NumberFormat((lng || 'fr').substr(0, 2), {
                                minimumFractionDigits: parseInt(decimals || '0'),
                                maximumFractionDigits: parseInt(decimals || '0'),
                            }).format(value);
                    }
                }

                return value.toString();
            },
        },
        react: {
            useSuspense: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 'span', 'p', 'small'],
        },
        detection: detectorOptions,
    });

i18next.languages = ['fr', 'en'];

export default i18next;
