import { cssBreakpoint } from 'device';
import styled from 'styled-components';

const ivrTheme = {
    ivr: {
        colors: {
            deepBlue: {
                75: 'rgba(0, 36, 84, 0.75)',
                100: 'rgba(0, 36, 84,1)',
            },
            golden: {
                25: 'rgba(187, 153, 129, 0.25)',
                100: 'rgba(187, 153, 129, 1)',
            },
            white: {
                50: 'rgba(255, 255, 255, 0.5)',
                100: 'rgba(255, 255, 255, 1)',
            },
            black: {
                50: 'rgba(0, 0, 0, 0.5)',
                100: 'rgba(0, 0, 0, 1)',
            },
            grey: {
                l90: 'rgba(238, 236, 232, 1)',
                d50: 'rgba(100, 100, 100, 1)',
                d90: 'rgba(52, 58, 64, 1)',
            },
        },
        fonts: {
            poppins: `'Poppins', var(--bs-font-sans-serif)`,
            playfair: `'Playfair Display', var(--bs-font-sans-serif)`,
        },
        header: {
            height: '65px',
            zIndex: 100,
        },
        buttons: {
            submit: {
                text: '#fff',
                base: {
                    backgroundColor: 'linear-gradient(rgb(199, 171, 151), rgb(195, 165, 144) 20%, rgb(185, 150, 126))',
                    borderColor: 'rgb(199, 171, 151);',
                },
                hover: {
                    backgroundColor: 'linear-gradient(rgb(185, 150, 126), rgb(195, 165, 144) 80%, rgb(199, 171, 151))',
                    borderColor: 'rgb(185, 150, 126);',
                },
            },
        },
    },
};

const MainContent = styled.div`
    padding-top: ${(props): string => props.theme.ivr.header.height};
    max-width: 100%;
    overflow-x: hidden;
    width: 100%;

    @media screen and ${cssBreakpoint('xl')} {
        padding-top: calc(${(props): string => props.theme.ivr.header.height} + 15px);
        overflow-x: visible;
    }
`;

export { ivrTheme, MainContent };
