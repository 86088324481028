exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-conditions-covid-19-index-tsx": () => import("./../../../src/pages/conditions-covid-19/index.tsx" /* webpackChunkName: "component---src-pages-conditions-covid-19-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-covid-19-condition-index-tsx": () => import("./../../../src/pages/covid-19-condition/index.tsx" /* webpackChunkName: "component---src-pages-covid-19-condition-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-last-minute-index-tsx": () => import("./../../../src/pages/last-minute/index.tsx" /* webpackChunkName: "component---src-pages-last-minute-index-tsx" */),
  "component---src-pages-legal-notice-index-tsx": () => import("./../../../src/pages/legal-notice/index.tsx" /* webpackChunkName: "component---src-pages-legal-notice-index-tsx" */),
  "component---src-pages-locations-index-tsx": () => import("./../../../src/pages/locations/index.tsx" /* webpackChunkName: "component---src-pages-locations-index-tsx" */),
  "component---src-pages-locations-rental-slug-tsx": () => import("./../../../src/pages/locations/{Rental.slug}.tsx" /* webpackChunkName: "component---src-pages-locations-rental-slug-tsx" */),
  "component---src-pages-mentions-legales-index-tsx": () => import("./../../../src/pages/mentions-legales/index.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-index-tsx" */),
  "component---src-pages-offres-speciales-index-tsx": () => import("./../../../src/pages/offres-speciales/index.tsx" /* webpackChunkName: "component---src-pages-offres-speciales-index-tsx" */),
  "component---src-pages-politique-de-confidentialite-index-tsx": () => import("./../../../src/pages/politique-de-confidentialite/index.tsx" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-rentals-index-tsx": () => import("./../../../src/pages/rentals/index.tsx" /* webpackChunkName: "component---src-pages-rentals-index-tsx" */),
  "component---src-pages-rentals-rental-slug-tsx": () => import("./../../../src/pages/rentals/{Rental.slug}.tsx" /* webpackChunkName: "component---src-pages-rentals-rental-slug-tsx" */),
  "component---src-pages-saint-barth-decouvrir-index-tsx": () => import("./../../../src/pages/saint-barth/decouvrir/index.tsx" /* webpackChunkName: "component---src-pages-saint-barth-decouvrir-index-tsx" */),
  "component---src-pages-saint-barth-discover-index-tsx": () => import("./../../../src/pages/saint-barth/discover/index.tsx" /* webpackChunkName: "component---src-pages-saint-barth-discover-index-tsx" */),
  "component---src-pages-saint-barth-trip-index-tsx": () => import("./../../../src/pages/saint-barth/trip/index.tsx" /* webpackChunkName: "component---src-pages-saint-barth-trip-index-tsx" */),
  "component---src-pages-saint-barth-voyager-index-tsx": () => import("./../../../src/pages/saint-barth/voyager/index.tsx" /* webpackChunkName: "component---src-pages-saint-barth-voyager-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-special-offers-index-tsx": () => import("./../../../src/pages/special-offers/index.tsx" /* webpackChunkName: "component---src-pages-special-offers-index-tsx" */),
  "component---src-templates-rental-index-tsx": () => import("./../../../src/templates/Rental/index.tsx" /* webpackChunkName: "component---src-templates-rental-index-tsx" */)
}

