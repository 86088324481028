// noinspection HtmlUnknownTarget,JSUnresolvedLibraryURL

import Footer from 'components/Layout/Footer';
import { cssBreakpoint } from 'device';
import withTrans from 'i18n/withTrans';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Header from './Header';
import { ivrTheme, MainContent } from './styled';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: 3,
        },
    },
});

const GlobalStyle = createGlobalStyle`
  :root {
    --bs-body-font-family: ${ivrTheme.ivr.fonts.poppins} !important;
  }

  body {
    padding-bottom: calc(420px + 3rem);
    position: relative;

    @media screen and ${cssBreakpoint('xl')} {
      padding-bottom: 400px;
    }
  }

  body, html {
    min-height: 100%;
  }
  
  #chat-application {
    bottom: 15vh !important;
  }
`;

// noinspection HtmlUnknownTarget,JSUnresolvedLibraryURL
const Layout: React.FC = ({ children }) => {
    const { t } = useTranslation();
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={ivrTheme}>
                <GlobalStyle />
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta httpEquiv="Accept-CH" content="DPR, Viewport-Width, Width" />
                    <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                    <link
                        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css"
                        rel="stylesheet"
                        integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We"
                        crossOrigin="anonymous"
                    />
                    <script
                        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/js/bootstrap.bundle.min.js"
                        integrity="sha384-U1DAWAznBHeqEIlVSCgzq+c9gqGAJn5c/t99JyeKa9xxaYpSvHU5awsuZVVFIhvj"
                        crossOrigin="anonymous"
                    />
                    <meta property="og:type" content="website" />
                    <meta name="msapplication-TileColor" content="#da532c" />
                    <meta name="theme-color" content="#ffffff" />
                    <link rel="manifest" href="/manifest.webmanifest" />
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;1,400;1,700&family=Poppins:wght@200;300;400;500;600&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
                <Container fluid className="g-0 p-0">
                    <Header />
                    <MainContent>{children}</MainContent>
                    <Footer />
                </Container>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default withTrans(Layout);
