import clsx from 'clsx';
import React, { ReactElement, useCallback } from 'react';
import { NavDropdown, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';

const locales = ['fr', 'en'];

const LanguageSwitcher = (): ReactElement => {
    const { i18n } = useTranslation();
    const handleChangeLanguage = useCallback(
        (lng): void => {
            // noinspection JSIgnoredPromiseFromCall
            i18n.changeLanguage(lng);
        },
        [i18n],
    );

    const currentLocale = (i18n.language || 'fr').substr(0, 2) as 'fr' | 'en';

    return (
        <>
            <NavDropdown
                title={
                    <>
                        {currentLocale}
                        <IoIosArrowDown className="ms-2" />
                    </>
                }
                as="li"
                className="fw-bold d-xl-inline d-none py-2 py-xl-0"
            >
                {locales.map((locale) => (
                    <NavDropdown.Item
                        href="#"
                        key={locale}
                        className="text-white"
                        onClick={(): any => handleChangeLanguage(locale)}
                    >
                        {locale}
                    </NavDropdown.Item>
                ))}
            </NavDropdown>
            <NavItem className="nav-item py-2 py-xl-0 d-inline d-xl-none">
                {locales.map((locale) => (
                    <a
                        className={clsx({
                            'nav-link': true,
                            'py-0': true,
                            'px-2': true,
                            'text-white': true,
                            'd-inline-block': true,
                            'fw-bold': currentLocale.toLowerCase() === locale.toLowerCase(),
                        })}
                        key={locale}
                        href="#"
                        onClick={(): any => handleChangeLanguage(locale)}
                    >
                        {locale}
                    </a>
                ))}
            </NavItem>
        </>
    );
};

export default LanguageSwitcher;
