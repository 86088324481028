import React, { Component, ComponentProps, ComponentType, ReactElement } from 'react';
import { I18nextProvider, withTranslation, WithTranslationProps } from 'react-i18next';
import i18next from './config';
import { SSRProvider } from '@react-aria/ssr';

const withTrans = (WrappedComponent: ComponentType<ComponentProps<any> & WithTranslationProps>): ComponentType => {
    const TranslatedComponent = withTranslation()(WrappedComponent);

    return class extends Component {
        render = (): ReactElement => (
            <SSRProvider>
                <I18nextProvider i18n={i18next}>
                    <TranslatedComponent {...this.props} language={i18next.language} />
                </I18nextProvider>
            </SSRProvider>
        );
    };
};

export default withTrans;
