import { cssBreakpoint } from 'device';
import { Link } from 'gatsby';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFooter = styled.footer`
    background-color: ${(props): string => props.theme.ivr.colors.deepBlue['100']};
    bottom: 0;
    left: 0;
    max-width: 100%;
    min-height: 250px;
    padding-bottom: 100px;
    right: 0;
    position: absolute;
    width: 100%;
    @media screen and ${cssBreakpoint('xl')} {
        min-height: 300px;
        padding-bottom: 0;
    }
`;

const FooterLink = styled(Link).attrs(() => ({
    className: 'text-white text-uppercase',
}))`
    text-decoration: none;
    @media screen and ${cssBreakpoint('xl')} {
        font-size: 0.65rem;
    }
`;

const Follow = styled(Col).attrs(({ className }) => ({
    className: `${className || ''} text-center d-flex justify-content-center align-items-stretch`,
}))`
    & > span {
        font-size: 1.2rem;
        font-weight: 200;
    }

    img {
        clear: none;
        display: inline-block;
        height: 20px;
    }
`;

const Spacer = styled(Col).attrs(() => ({
    xs: 12,
}))`
    border-bottom: 1px solid ${(props): string => props.theme.ivr.colors.golden['100']};
`;

export { Follow, FooterLink, Spacer, StyledFooter };
